<template>
  <div class="union-base">
    <div class="union-header">
      <img src="@/assets/uExam/logo.png" alt="" />
      <div class="text">
        {{ $route.query.name }}
      </div>
      <a-button type="primary" style="margin-left: 8px" @click="changeExam"
        >切换</a-button
      >
    </div>
    <div class="union-card">
      <div
        class="union-nav"
        :style="{
          width: collapsed ? 'auto' : '240px',
          minWidth: collapsed ? '0' : '240px',
        }"
      >
        <a-menu
          :selected-keys="[$route.path]"
          mode="inline"
          :open-keys.sync="openKey"
          :inline-collapsed="collapsed"
          selectable
          @select="selectMenu"
        >
          <a-sub-menu key="/total">
            <span slot="title">
              <img
                v-if="openKey == '/total'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/aggregate analysis_select_icon02@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/aggregate analysis_default_icon01@1x.png"
                alt=""
              />
              <span v-if="!collapsed">总体分析</span>
            </span>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/total/total"
            >
              总体情况分析
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1 && statInfo.hasArea == 1"
              key="/union/total/area"
            >
              各地区总体情况分析
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/total/totalSegment"
            >
              总分分数段分布
            </a-menu-item>
            <a-menu-item key="/union/total/subjectSegment">
              单科分数段分布
            </a-menu-item>
            <a-menu-item key="/union/total/grade"> 成绩榜 </a-menu-item>
            <a-menu-item v-if="!isNewExam" key="/union/total/level">
              等级成绩榜
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/comparison">
            <span slot="title">
              <img
                v-if="openKey == '/comparison'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/grade comparison_select_icon03@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/grade comparison_default_icon03@1x.png"
                alt=""
              /><span v-if="!collapsed">各校成绩对比</span>
            </span>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/comparison/total"
            >
              总分各项指标对比
            </a-menu-item>
            <a-menu-item key="/union/comparison/subject">
              单科各项指标对比
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/comparison/summary"
            >
              各项指标对比汇总
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1 && isUnionSubjectUser != 1"
              key="/union/comparison/class"
            >
              班级各项指标对比
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/comparison/totalSegment"
            >
              总分分数段对比
            </a-menu-item>
            <a-menu-item key="/union/comparison/subjectSegment">
              单科分数段对比
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1 && !isNewExam"
              key="/union/comparison/totalN"
            >
              总分前N名对比
            </a-menu-item>
            <a-menu-item key="/union/comparison/subjectN">
              单科前N名对比
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/comparison/quality"
            >
              学科质量对比分析
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/comparison/rate"
            >
              学科总体评价
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/questions">
            <span slot="title">
              <img
                v-if="openKey == '/questions'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/test item analysis_select_icon06@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/test item analysis_default_icon05@1x.png"
                alt=""
              /><span v-if="!collapsed">试题分析</span>
            </span>
            <a-menu-item key="/union/quesitons/data">
              试题数据分析
            </a-menu-item>
            <a-menu-item key="/union/quesitons/choice">
              选择题数据分析
            </a-menu-item>
            <a-menu-item key="/union/quesitons/notChoice">
              非选择题数据分析
            </a-menu-item>
            <a-menu-item key="/union/quesitons/options">
              选做题作答分析
            </a-menu-item>
            <a-menu-item key="/union/quesitons/school">
              常用指标各校对比
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1"
              key="/union/quesitons/class"
            >
              常用指标班级对比
            </a-menu-item>
            <a-menu-item key="/union/quesitons/detail">
              试题得分详细分布
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/paper">
            <span slot="title">
              <img
                v-if="openKey == '/paper'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/propositional analysis_select_icon08@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/propositional analysis_default_icon07@1x.png"
                alt=""
              /><span v-if="!collapsed">试卷命题分析</span>
            </span>

            <a-menu-item key="/union/paper/subject">
              学科得分率分布
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/paper/reliability"
            >
              试卷信度统计
            </a-menu-item>
            <a-menu-item key="/union/paper/total"> 试题汇总分析 </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/paper/diffcultly"
            >
              难度等级分布
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/paper/discrimination"
            >
              区分度等级分布
            </a-menu-item>
            <a-menu-item key="/union/paper/diffcultlyBranch">
              难度指数分布图
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/characteristic">
            <span slot="title">
              <img
                v-if="openKey == '/characteristic'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/characteristic examination paper_select_icon10@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/characteristic examination paper_default_icon09@1x.png"
                alt=""
              /><span v-if="!collapsed">特征试卷</span>
            </span>
            <a-menu-item key="/union/characteristic/typical">
              典型试卷
            </a-menu-item>
            <a-menu-item key="/union/characteristic/highScore">
              高分小题
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="!isNewExam" key="/online">
            <span slot="title">
              <img
                v-if="openKey == '/online'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/top line_select_icon12@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/top line_default_icon11@1x.png"
                alt=""
              /><span v-if="!collapsed">上线情况</span>
            </span>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/online/total"
            >
              总体情况
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/online/school"
            >
              各校总分上线情况
            </a-menu-item>
            <a-menu-item key="/union/online/subject">
              各校单科上线情况
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/online/totalSubject"
            >
              各校各学科上线情况
            </a-menu-item>
            <a-menu-item
              v-if="isUnionSubjectUser != 1"
              key="/union/online/create"
            >
              生成上线情况
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="/school">
            <span slot="title">
              <img
                v-if="openKey == '/school'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/school analysis_select_icon14@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/school analysis_default_icon13@1x.png"
                alt=""
              /><span v-if="!collapsed">学校分析</span>
            </span>
            <a-menu-item key="/union/school/grade"> 年级成绩榜 </a-menu-item>
            <a-menu-item v-if="!isNewExam" key="/union/school/gradeLevel">
              年级等级榜
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1"
              key="/union/school/class"
            >
              班级成绩榜
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1 && !isNewExam"
              key="/union/school/classLevel"
            >
              班级等级榜
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1"
              key="/union/school/target"
            >
              班级各项指标对比
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1"
              key="/union/school/segment"
            >
              班级分数段对比
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1"
              key="/union/school/paper"
            >
              试卷分析
            </a-menu-item>
            <a-menu-item
              v-if="statInfo.statClass == 1"
              key="/union/school/slip"
            >
              成绩条
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu
            v-if="statInfo.type == 1 && isUnionSubjectUser != 1"
            key="/importToSchool"
          >
            <span slot="title">
              <img
                v-if="openKey == '/importToSchool'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/import to school_select_icon16@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/import to school_default_icon15@1x.png"
                alt=""
              /><span v-if="!collapsed">导入到学校</span>
            </span>
            <a-menu-item key="/union/importToSchool/index">
              导入到学校
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="isUnionSubjectUser != 1" key="/muchExport">
            <span slot="title">
              <img
                v-if="openKey == '/muchExport'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/bulk export_select_icon18@1x.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/bulk export_default_icon17@1x.png"
                alt=""
              /><span v-if="!collapsed">批量导出</span>
            </span>
            <a-menu-item key="/union/muchExport/index"> 批量导出 </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="userType != 7" key="/senior">
            <span slot="title">
              <img
                v-if="openKey == '/senior'"
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/senior_select.png"
                alt=""
              />
              <img
                v-else
                :class="collapsed ? 'menu-icon-collapsed' : 'menu-icon'"
                src="@/assets/uExam/sheet/senior.png"
                alt=""
              /><span v-if="!collapsed">高级功能</span>
            </span>
            <a-menu-item key="/union/senior/paper">
              导出考生答题卡
            </a-menu-item>
            <a-menu-item key="/union/senior/data"> 导出原始数据 </a-menu-item>
          </a-sub-menu>
        </a-menu>
        <div
          :class="['menu-fold', collapsed ? 'menu-fold-active' : '']"
          @click="toggleCollapsed"
        >
          <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
        </div>
      </div>
      <div
        class="union-content"
        :style="{
          width: collapsed ? 'calc(100vw - 88px)' : 'calc(100vw - 240px)',
        }"
      >
        <router-view :key="contentKey" />
      </div>
    </div>
    <a-modal v-model="visible" title="切换考试" @ok="handleOk">
      <div class="filter">
        <div class="filter-item">
          <div class="label">入学年份</div>

          <a-select v-model="year" style="width: 350px" @change="yearChange">
            <a-select-option
              v-for="(item, index) in yearList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </div>
        <div class="filter-item">
          <div class="label">选择考试</div>

          <a-select v-model="statId" style="width: 350px" @change="examChange">
            <a-select-option
              v-for="(item, index) in examList"
              :key="index"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getstatscoretypesetting } from "@/core/api/academic/newExam";
import { getunionlist } from "@/core/api/academic/statistics";
import { getstatinfo, getstatunionuserinfo } from "@/core/api/academic/common";
export default {
  name: "UnionBase",
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearData = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    let yearList = yearData.reverse();
    return {
      collapsed: false,
      visible: false,
      yearList: [...yearList],
      examList: [],
      year: null,
      examId: null,
      examObj: {},
      contentKey: Math.random(),
      openKey: [],
      menuKey: [],
      statInfo: {},
      userType: null,
      screenHeight: 0,
      isUnionSubjectUser: this.$route.query.isUnionSubjectUser, // 为1的时候是 单科数据查询用户
      isNewExam: false,
      statId: null,
    };
  },

  created() {
    this.getstatscoretypesettingData();
    this.openKey = [this.$route.meta.subMenu];
    this.menuKey = [this.$route.fullPath];
    this.getstatinfoData();
    this.getstatunionuserinfoData();
    this.year = Number(this.$route.query.year);
    this.isNewExam =
      this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0;
  },
  methods: {
    async getstatunionuserinfoData() {
      const res = await getstatunionuserinfo({ id: this.$route.query.id });
      this.isUnionSubjectUser = res.data.data.isUnionSubjectUser;
      this.userType = res.data.data.userType;
    },
    async getstatinfoData() {
      const res = await getstatinfo({ id: this.$route.query.id });
      this.statInfo = res.data.data.stat;
    },
    handleOk() {
      if (!this.statId) {
        this.$message({
          showClose: true,
          message: "请选择考试",
          type: "warning",
        });
      } else {
        this.visible = false;
        this.$router.replace({
          path: this.$route.path,
          query: {
            year: this.year,
            examId: this.examId,
            id: this.examObj.id,
            name: this.examObj.name,
            examType: this.examObj.examType,
            type: this.examObj.type,
            level: this.examObj.level,
            examDate: this.examObj.examDate,
            ruleId: this.examObj.ruleId,
            scoreType: this.examObj.scoreType,
          },
        });
        this.contentKey = Math.random();
        this.$store.commit("report/SET_SCORE_TYPE", this.examObj.scoreType);
        this.isNewExam = this.examObj.scoreType == 1 && this.examObj.ruleId > 0;
      }
    },
    async yearChange() {
      const res = await getunionlist({
        examType: 16,
        examYear: this.year.toString(),
      });
      this.examList = res.data.data;
      this.statId = null;
      if (this.examList.length > 0) {
        this.statId = this.examList[0].id;
      }
      // this.examId = null;
      // this.statId = null;
    },
    examChange() {
      this.examObj = this.examList.find((item) => item.id === this.statId);
    },
    async changeExam() {
      const res = await getunionlist({
        examType: 16,
        examYear: this.$route.query.year,
      });
      this.examList = res.data.data;
      this.year = Number(this.$route.query.year);
      this.statId = Number(this.$route.query.id);
      this.examChange();
      this.visible = true;
    },
    async getstatscoretypesettingData() {
      const res = await getstatscoretypesetting({ id: this.$route.query.id });
      this.$store.commit("report/SET_SCORE_TYPE", res.data.data);
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    selectMenu({ selectedKeys }) {
      let path = selectedKeys[0];
      this.$router.push({ path: path, query: { ...this.$route.query } });
    },
  },
};
</script>
<style lang="scss" scoped>
.union-base {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  .union-header {
    height: 44px;
    background: #2e3137;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 24px;
    color: #ffffff;
    img {
      margin-right: 24px;
    }
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .union-card {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 44px);
    .union-nav {
      margin-right: 8px;
      background-color: #fff;
      height: calc(100vh - 44px);
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar-track-piece {
        //滚动条凹槽的颜色，还可以设置边框属性
        background-color: #f8f8f8;
      }
      &::-webkit-scrollbar {
        //滚动条的宽度
        width: 9px;
        height: 9px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        //滚动条的设置
        background-color: #dddddd;
        background-clip: padding-box;
        min-height: 28px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #bbb;
      }
      min-width: 240px;
    }
    .union-content {
      background: #ffffff;
      box-sizing: border-box;
      height: 100%;
      padding: 18px;
      overflow-x: auto;
      height: calc(100vh - 44px);
    }
  }
}
.menu-fold {
  cursor: pointer;
  margin: 16px auto;
  text-align: left;
  box-sizing: border-box;
  padding: 0 24px;
}
.menu-fold-active {
  text-align: center;
}
.ant-menu-item-selected {
  background-color: #f2f6ff !important;
  color: #2474ed !important;

  &::after {
    border-right: none !important;
  }
}
.filter {
  width: 100%;
  .filter-item {
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    .label {
      margin-right: 8px;
    }
  }
}
.menu-icon {
  width: 14px;
  margin-right: 12px;
  transition: all 0.5s linear;
}
.menu-icon-collapsed {
  width: 16px;
  transition: all 0.5s linear;
}
::v-deep .ant-menu-submenu-title {
  color: #0a1119;
}
</style>
